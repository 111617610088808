.list {
  user-select: none;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 16px;
  justify-items: center;
  align-items: center;
  width: 100%;
  max-width: 1500px;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Color del texto */
  height: 250px;
  cursor: grab;
  font-size: 20px;
  user-select: none;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%; /* Asegura que ocupe todo el ancho disponible */
  max-width: 350px; /* Ajusta el tamaño máximo de cada item */
  position: relative; /* Necesario para la propiedad z-index del texto */
  overflow: hidden; /* Asegura que la imagen de fondo no se desborde */
}

.dragged {
  background: linear-gradient(92deg, #5a4e99 0%, #968ad5 100%);
}

/* Media query para pantallas más grandes */
@media (min-width: 600px) {
  .list {
    grid-gap: 24px;
  }
}

/* Media query para pantallas menores a 768px */
@media (max-width: 1100px) {
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .item {
    width: calc(50% - 16px);
    max-width: 200px;
  }
}

/* Media query para pantallas aún más pequeñas */
@media (max-width: 480px) {
  .item {
    width: 100%;
  }
}
