@keyframes dialog-slide-in {
  0% {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes dialog-slide-out {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
}

.animate-dialog-slide-in {
  animation: dialog-slide-in 0.3s ease-out;
}

.animate-dialog-slide-out {
  animation: dialog-slide-out 0.2s ease-in;
}
